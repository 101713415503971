<template>
  <CFooter :fixed="false">
    <div>
      &copy; <a href="//gruetter-fahrschule.ch" target="_blank">Grütter Stooss AG</a>
    </div>
    <div class="mfs-auto small">
      <span class="mr-1" >Entwickelt von</span>
      <a href="//globalelements.ch" target="_blank">Global Elements GmbH</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
};
</script>
