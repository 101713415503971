<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <img src="https://gruetter-fahrschule.ch/wp-content/uploads/2016/07/logo_dark.png"
           alt="Grütter Stooss AG" />
    </CHeaderBrand>
    <CHeaderNav class="mr-4">
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>
  </CHeader>
</template>

<script>
export default {
  name: 'TheHeader',
}
</script>
